<template>
  <div>
    <div class="row">
      <div class="col col-lg-12">
        <v-autocomplete
          v-model="paciente"
          :items="listPacientes"
          :loading="carregando"
          :search-input.sync="search"
          color="black"
          hide-no-data
          hide-selected
          item-text="no_usuario"
          item-value="id_usuario_gms"
          label="Paciente"
          placeholder="Digite algo ..."
          prepend-icon="mdi-database-search"
          return-object
          @change="selecionaPaciente"
          ref="autocompletePaciente"
        ></v-autocomplete>
      </div>
    </div>
  </div>
</template>

<script>
	import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
	import {LOGOUT, VERIFY_AUTH} from "../../../core/services/store/auth.module";
	import ApiService from "@/core/services/api.service";

	export default {
		name: "ab-tab-pacientes",
		components: {},
		data() {
			return {
				pacientes: [],
				carregando: false,
				paciente: null,
				search: null
			}
		},
		mounted() {
		},
		computed: {
			listPacientes() {
				return this.pacientes;
			}
		},
		methods: {
			buscaPacientes(val) {
				//console.log('buscando');
        if (!this.carregando){
	        this.carregando = true;
	        ApiService.post('pacientes',{paciente: val})
		        .then(ret => {
			        this.pacientes = ret.data.pacientes;
		        })
		        .catch()
		        .finally(()=>{
			        this.carregando = false;
		        });

        }
			},
      selecionaPaciente() {
				this.$emit('selecionouPaciente',this.paciente);
      },
		},
		created() {
			ApiService.setHeader();
		},
    watch: {
	    search (val) {
	    	if (val && val.length>=5) {
			    val && val !== this.usuario && this.buscaPacientes(val);
		    }
	    }
    }
	}
</script>
