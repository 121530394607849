<template>
  <div>
    <div class="row">
      <div class="col col-lg-12">
        <v-autocomplete
          v-model="usuario"
          :items="listUsers"
          :loading="carregando"
          :search-input.sync="search"
          color="black"
          hide-no-data
          hide-selected
          item-text="nome"
          item-value="id"
          label="Usuários do Sistema"
          placeholder="Digite algo ..."
          prepend-icon="mdi-database-search"
          return-object
          @change="selecionaUsuario"
          ref="autocompleteForma"
        ></v-autocomplete>
      </div>
    </div>
  </div>
</template>

<script>
	import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
	import {LOGOUT, VERIFY_AUTH} from "../../../core/services/store/auth.module";
	import ApiService from "@/core/services/api.service";

	export default {
		name: "ab-tab-users",
		components: {},
		data() {
			return {
				usuarios: [],
				carregando: false,
				usuario: null,
				search: null
			}
		},
		mounted() {
			this.buscaUsuarios();
		},
		computed: {
			listUsers() {
				return this.usuarios;
			}
		},
		methods: {
			buscaUsuarios(val) {
				//console.log('buscando');
        if (!this.carregando){
	        this.carregando = true;
	        ApiService.get('users')
		        .then(ret => {
			        this.usuarios = ret.data.usuarios;
		        })
		        .catch()
		        .finally(()=>{
			        this.carregando = false;
		        });

        }
			},
      selecionaUsuario() {
				this.$emit('selecionouUsuarioSistema',this.usuario);
      },
		},
		created() {
			ApiService.setHeader();
		},
    watch: {
	    /*search (val) {
	    	if (val && val.length>=5) {
			    val && val !== this.usuario && this.buscaUsuarios(val);
		    }
	    }*/
    }
	}
</script>
