<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row text-center">
      <div class="col-xl-12" v-if="api" style="padding: 0px !important;">
        <!--Botão Encerrar Sala-->
        <v-tooltip top z-index="2000">
          <template v-slot:activator="{ on, attrs }">
            <b-button variant="primary" @click="encerrarSala" v-bind="attrs" v-on="on">
              <i class="material-icons valign-wrapper">stop</i>
            </b-button>
          </template>
          <span>Encerra a Sala</span>
        </v-tooltip>
        <!--Muda Nome da Sala-->
        <!--<v-tooltip top z-index="2000">
          <template v-slot:activator="{ on, attrs }">
            <button v-bind="attrs" v-on="on"
                    class="btn btn-primary font-weight-bold px-4 py-2 my-1 ma-5 font-size-3"
                    @click="mudaSubject">
              <i class="material-icons valign-wrapper">change_history</i>
            </button>
          </template>
          <span>Muda Nome da Sala</span>
        </v-tooltip>-->
        <!--Botão de Mute do Microfone-->
        <v-tooltip top z-index="2000">
          <template v-slot:activator="{ on, attrs }">
            <button v-bind="attrs" v-on="on"
                    class="btn btn-primary font-weight-bold px-4 py-2 my-1 ma-5 font-size-3"
                    :class="{'red darken-4': volume=='volume_off'}"
                    @click="toggleAudio">
              <i class="material-icons valign-wrapper">{{ volume }}</i>
            </button>
          </template>
          <span v-if="volume=='volume_up'">Desliga o Microfone</span>
          <span v-else>Liga o Microfone</span>
        </v-tooltip>
        <!--Botão de Mute do Video-->
        <v-tooltip top z-index="2000">
          <template v-slot:activator="{ on, attrs }">
            <button v-bind="attrs" v-on="on"
                    class="btn btn-primary font-weight-bold px-4 py-2 my-1 ma-5 font-size-3"
                    :class="{'red darken-4': camera=='videocam_off'}"
                    @click="toggleVideo">
              <i class="material-icons valign-wrapper">{{ camera }}</i>
            </button>
          </template>
          <span v-if="camera=='videocam'">Desliga a Câmera</span>
          <span v-else>Liga a Câmera</span>
        </v-tooltip>
        <!--Botão de Envio de SMS-->
        <v-tooltip top z-index="2000">
          <template v-slot:activator="{ on, attrs }">
            <button v-bind="attrs" v-on="on"
                    ref="bt_envia_sms"
                    class="btn btn-primary font-weight-bold px-4 py-2 my-1 ma-5 font-size-3"
                    @click="sendSMS">
              <i class="material-icons valign-wrapper">sms</i>
            </button>
          </template>
          <span>Envia SMS para os participantes</span>
        </v-tooltip>

        <!--Botão do Whats-->
        <v-tooltip top z-index="2000" v-if=" sala && sala.participantes.length==1">
          <template v-slot:activator="{ on, attrs }">
            <button
                @click='apiWhats'
                target="_blank" class="btn font-weight-bold px-4 py-2 my-1 ma-5 font-size-3" v-on="on"
                :class="{
                  'btn-primary':true,
                  'btn-danger':sala.participantes[0].paciente.fone_celular.substr(0,1)!='9'
                             ||sala.participantes[0].paciente.ddd==''}">
              {{sala.participantes[0].paciente.ddd+' '+sala.participantes[0].paciente.fone_celular}}
              <i class="mdi mdi-18px mdi-whatsapp"></i>
            </button>
          </template>
          <span>Abre Link no Whats</span>
        </v-tooltip>

        <!--Botão de Copiar Link-->
        <v-tooltip top z-index="2000" v-if="sala && sala.participantes.length==1">
          <template v-slot:activator="{ on, attrs }">
            <button
                @click="copia(link + '/#/login?p='+sala.participantes[0].id)"
                target="_blank" class="btn btn-primary font-weight-bold px-4 py-2 my-1 ma-5 font-size-3" v-on="on">
              <i class="mdi mdi-18px mdi-content-copy "></i>
            </button>
          </template>
          <span>Copia Link</span>
        </v-tooltip>


        <!--Botão de Verificação de SMS-->
        <!--<v-tooltip top z-index="2000">
          <template v-slot:activator="{ on, attrs }">
            <button v-bind="attrs" v-on="on"
                    class="btn btn-primary font-weight-bold px-4 py-2 my-1 ma-5 font-size-3"
                    @click="getStatusSMS()" :disabled="identificador==null">
              <i class="material-icons valign-wrapper">sms_failed</i>
            </button>
          </template>
          <span>Verifica Status do SMS Enviado</span>
        </v-tooltip>-->
        <!--Botão de Tela Cheia-->
        <v-tooltip top z-index="2000">
          <template v-slot:activator="{ on, attrs }">
            <button v-if="api" v-bind="attrs" v-on="on"
                    class="btn btn-primary font-weight-bold px-4 py-2 my-1 ma-5 font-size-3"
                    @click="fullScreen()">
              <i class="material-icons valign-wrapper">fullscreen</i>
            </button>
          </template>
          <span>Tela Cheia</span>
        </v-tooltip>
      </div>
    </div>
    <form action="">
      <div class="row" v-if="!api && params.tipo_integracao=='G-MUS'">
        <!--Autocomplete da Forma-->
        <div class="col-xl-4">
          <v-autocomplete
              v-model="formaSelecionada"
              :items="formas"
              :loading="carregandoFormas"
              :search-input.sync="search"
              color="black"
              hide-no-data
              hide-selected
              item-text="descricao"
              item-value="sforma"
              label="Formas"
              placeholder="Digite algo ..."
              prepend-icon="mdi-database-search"
              return-object
              @change="buscaProfissionais"
              ref="autocompleteForma"
          ></v-autocomplete>
        </div>
        <!--Select do Profissional-->
        <div class="col-xl-4">
          <v-select v-show="formaSelecionada"
                    v-model="profissionalSelecionado"
                    :items="profissionais"
                    :loading="isLoadingProfissional"
                    prepend-icon="mdi-database-search"
                    color="black"
                    item-text="descricao"
                    item-value="codigo"
                    label="Profissional"
                    return-object
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  Nenhum Profissional com Agenda para esta Forma
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </div>
        <!--Botão de Criação de Sala-->
        <div class="col-xl-2">
          <button @click="criaSalaProfissional" v-if="!api && profissionalSelecionado"
                  ref="bt_cria_sala_profissional"
                  class="btn btn-primary right font-weight-bold px-9 py-4 my-3 font-size-3"
                  :disabled="!profissionalSelecionado ||
                             profissionalSelecionado.ddd==null ||
                             profissionalSelecionado.fone_celular==null">
            Criar Sala
          </button>
        </div>
      </div>
      <!--TABPAGE Agenda, Grupos e Usuários -->
      <v-tabs
          v-if="!api"
          v-model="tab"
          background-color="deep-purple accent-4"
          centered
          dark
          icons-and-text
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#tab-1" v-if="params.tipo_integracao=='G-MUS'">
          Agenda
          <v-icon>mdi-calendar</v-icon>
        </v-tab>
        <v-tab href="#tab-2" v-if="params.tipo_integracao=='G-MUS'">
          Grupos
          <v-icon>mdi-account-group</v-icon>
        </v-tab>
        <v-tab href="#tab-3">
          Pacientes
          <v-icon>mdi-account</v-icon>
        </v-tab>
      </v-tabs>
      <!--Itens do Tabpage-->
      <v-tabs-items v-model="tab" v-if="!api">
        <!-- Tab da Agenda -->
        <v-tab-item value="tab-1" v-if="params.tipo_integracao=='G-MUS'">
          <v-card flat>
            <v-card-text>
              <div class="row" v-if="!api">
                <div class="col-xl-12">
                  <b-table :items="agendamentos" :fields="camposTabelaAgenda" striped>
                    <template v-slot:cell(index)="data">
                      {{ data.index + 1 }}
                    </template>
                    <template v-slot:cell(id_siagprf)="data">
                      <a class="fas fa-desktop" @click="criaSalaPaciente(data.value)">
                        <!--<span class="svg-icon svg-icon-primary svg-icon-2x">
                          &lt;!&ndash;begin::Svg Icon | path:/home/keenthemes/www/metronic/themes/metronic/theme/html/demo1/dist/../src/media/svg/icons/Media/Airplay-video.svg&ndash;&gt;
                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24"/>
                                <path d="M7,15 C7.55228475,15 8,15.4477153 8,16 C8,16.5522847 7.55228475,17 7,17 L6,17 C4.34314575,17 3,15.6568542 3,14 L3,7 C3,5.34314575 4.34314575,4 6,4 L18,4 C19.6568542,4 21,5.34314575 21,7 L21,14 C21,15.6568542 19.6568542,17 18,17 L17,17 C16.4477153,17 16,16.5522847 16,16 C16,15.4477153 16.4477153,15 17,15 L18,15 C18.5522847,15 19,14.5522847 19,14 L19,7 C19,6.44771525 18.5522847,6 18,6 L6,6 C5.44771525,6 5,6.44771525 5,7 L5,14 C5,14.5522847 5.44771525,15 6,15 L7,15 Z" fill="#000000" fill-rule="nonzero"/>
                                <polygon fill="#000000" opacity="0.3" points="8 20 16 20 12 15"/>
                            </g>
                          </svg>&lt;!&ndash;end::Svg Icon&ndash;&gt;
                        </span>-->
                      </a>
                    </template>
                    <template v-slot:cell(paciente.telefones)="data">
                      <li v-for="i in data.value">{{ i.ddd }}-{{ i.numero }}({{ i.tipo }}/{{ i.data_cadastro }})</li>
                    </template>

                    <!--<template v-slot:cell(paciente.end_nr_fone_celular)="data">
                      <div class="row">
                        <b-input v-if="data.value.length<9" size="sm" class="col-md-8 ml-5"
                                 @blur="data.item.paciente.end_nr_fone_celular=$event.target.value"
                                 model="data.item.paciente.end_nr_fone_celular"></b-input>
                      </div>
                      <div>
                        {{data.value}}
                      </div>
                      &lt;!&ndash;<b-btn v-else >
                        &lt;!&ndash;<b-icon icon="pencil-square" @click="data.item.mostra=true"></b-icon>&ndash;&gt;
                      </b-btn>&ndash;&gt;
                    </template>-->
                    <!--<template v-slot:cell(paciente.end_ddd_celular)="data">
                      <div class="row">
                        <b-input v-if="data.value.length<2" size="sm" class="col-md-3 ml-5"
                                 @blur="data.item.paciente.end_ddd_celular=$event.target.value"
                                 model="data.item.paciente.end_ddd_celular"></b-input>
                      </div>
                    </template>-->

                  </b-table>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- TAB do Grupo -->
        <v-tab-item value="tab-2" v-if="params.tipo_integracao=='G-MUS'">
          <!-- Autocomplete de Grupos-->
          <v-row>
            <v-col xl="8" md="8">
              <v-autocomplete
                  v-model="grupoSelecionado"
                  :items="grupos"
                  :loading="carregandoGrupos"
                  :search-input.sync="searchGrupos"
                  primary-key="id_iaggru"
                  color="black"
                  hide-no-data
                  hide-selected
                  item-text="ds_iaggru"
                  item-value="id_iaggru"
                  label="Grupos"
                  placeholder="Digite algo ..."
                  prepend-icon="mdi-database-search"
                  return-object
                  @change="buscaParticipantes"
                  ref="autocompleteGrupos"
              >
                <template slot='item' slot-scope='{ item }'>
                  {{ item.ds_iaggru }} - {{ item.dt_iaggru }}
                </template>
              </v-autocomplete>
            </v-col>
            <v-col xl="2" md="3">
              <button @click="criaSalaGrupo" :disabled="selected.length==0 || !telefonesGrupoValidos"
                      ref="bt_cria_sala_grupo"
                      class="btn btn-primary right font-weight-bold px-9 py-4 my-3 font-size-3">
                Criar Sala
              </button>
            </v-col>
          </v-row>
          <!--Detalhes do Grupo Selecionado-->
          <v-row>
            <v-col xl="3" v-if="grupoSelecionado">
              <strong>Data: </strong>{{ grupoSelecionado.dt_iaggru }}
            </v-col>
            <v-col xl="3" v-if="grupoSelecionado">
              <strong>Horário: </strong>{{ grupoSelecionado.horario }}
            </v-col>
            <v-col xl="3" v-if="grupoSelecionado">
              <strong>Ups: </strong>{{ grupoSelecionado.ups.no_ups }}
            </v-col>
          </v-row>
          <!--Tabela de Agendamentos em Grupo-->
          <v-row>
            <v-card flat>
              <v-card-text>
                <div class="row" v-if="!api">
                  <div class="col-xl-12">
                    <b-table :items="agendamentosGrupo"
                             :fields="camposTabelaGrupo" striped
                             selectable
                             selectAllRows
                             ref="tabelaAgendamentoGrupo"
                             @row-selected="onRowSelected"
                             :busy="tabelaGrupoBusy"
                    >

                      <template v-slot:cell(selected)="{ rowSelected }">
                        <template v-if="rowSelected">
                          <span aria-hidden="true">&check;</span>
                          <span class="sr-only">Selected</span>
                        </template>
                        <template v-else>
                          <span aria-hidden="true">&nbsp;</span>
                          <span class="sr-only">Not selected</span>
                        </template>
                      </template>

                      <!--<template v-slot:cell(paciente.end_nr_fone_celular)="data">
                        <div class="row">
                          <b-input v-if="data.value.length<9" size="sm" class="col-md-10 ml-5"
                                   @blur="data.item.paciente.end_nr_fone_celular=$event.target.value"
                                   model="data.item.paciente.end_nr_fone_celular"></b-input>
                        </div>
                        <div>
                          {{data.value}}
                        </div>
                        &lt;!&ndash;<b-btn v-else >
                          &lt;!&ndash;<b-icon icon="pencil-square" @click="data.item.mostra=true"></b-icon>&ndash;&gt;
                        </b-btn>&ndash;&gt;
                      </template>-->
                      <!--<template v-slot:cell(paciente.end_ddd_celular)="data">
                        <div class="row">
                          <b-input v-if="data.value.length<2" size="sm" class="col-md-3 ml-5"
                                   @blur="data.item.paciente.end_ddd_celular=$event.target.value"
                                   model="data.item.paciente.end_ddd_celular"></b-input>
                        </div>
                        <div>
                          {{data.value}}
                        </div>
                        &lt;!&ndash;<b-btn v-else >
                          &lt;!&ndash;<b-icon icon="pencil-square" @click="data.item.mostra=true"></b-icon>&ndash;&gt;
                        </b-btn>&ndash;&gt;
                      </template>-->

                      <!--<template v-slot:cell(id_ugmsiaggru)="data">
                        <v-checkbox v-model="data.selected" :value="false"></v-checkbox>
                        {{data.selected}}
                      </template>-->

                      <template v-slot:table-busy>
                        <div class="text-center text-danger my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>Buscando...</strong>
                        </div>
                      </template>

                    </b-table>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-row>
        </v-tab-item>
        <!-- TAB dos Pacientes do sistema-->
        <v-tab-item value="tab-3">
          <v-card flat>
            <v-card-text>
              <div class="row">
                <v-col xl="8" md="8">
                  <ab-tab-pacientes @selecionouPaciente="pacienteFoiSelecionado"></ab-tab-pacientes>
                </v-col>
                <v-col xl="2" md="3">
                  <button @click="criaSalaPacienteAvulso" :disabled="!pacienteSelecionado"
                          ref="bt_cria_sala_paciente_avulso"
                          class="btn btn-primary right font-weight-bold px-9 py-4 my-3 font-size-3">
                    Criar Sala
                  </button>
                </v-col>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </form>
    <!--Container principal da video-->
    <div class="row">
      <div class="col-xl-12" style="padding: 5px;">
        <div id="meet" style="height: 75vh" ref="meet"></div>
      </div>
    </div>
    <!--Envio de Arquivo-->
    <div class="row" v-if="api">
      <v-file-input show-size label="File input"
                    ref="file" @change="handleUpload"
                    multiple
                    :loading="enviandoArquivo"
                    v-model="arquivoSelecionado"></v-file-input>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import KTDropdownNotification from "@/view/layout/extras/dropdown/DropdownNotification.vue";
import {SET_SALA, BUSCA_FORMAS, VERIFY_AUTH, FECHA_SALA, RESET,ADD_FILE} from "../../../core/services/store/auth.module";
import ApiService from "@/core/services/api.service";
import AbTabUsers from "./ABTabUsers";
import {LINK, DOMAIN} from "@/config";
import AbTabPacientes from "@/view/pages/Tele/ABTabPacientes";

export default {
  name: "telesaude",
  components: {AbTabPacientes, AbTabUsers, KTDropdownNotification},
  data() {
    return {
      link: LINK,
      tipoEvento: '',
      file: '',
      arquivoSelecionado: null,
      enviandoArquivo: false,      //Usando no componente de File Upload para indicar se o mesmo está sendo enviado
      isLoadingProfissional: false,//Usado no Select de Profissionais para indicar se está 'carregando'
      isLoadingForma: false,       //Usando no Autocomplete de formas, para indicar se esta 'carregando'
      search: null,
      tab: null,

      /*TAB Grupos*/
      selected: [],               //Matem as linhas que foram selecionadas na tabela de Grupos
      grupos: [],                 //Mantem os grupos vindos da pesquisa realizada
      tabelaGrupoBusy: false,     //Indica quando o 'spin' de carregando deve ser mostrado na tabela de grupos
      carregandoGrupos: false,
      searchGrupos: '',
      grupoSelecionado: null,
      agendamentosGrupo: [],
      camposTabelaGrupo: [            //Campos para montagem da tabela de Atendimento em Grupo
        {key: 'selected', label: ''},
        {key: 'paciente.no_usuario', label: 'Paciente'},
        {key: 'paciente.dt_nascimento', label: 'Dt. Nascimento'},
        {key: 'paciente.in_sexo', label: 'Sexo'},
        {key: 'paciente.ddd', label: 'DDD'},
        {key: 'paciente.fone_celular', label: 'Celular'},
      ],


      profissional: '',
      camposTabelaAgenda: [
        {key: 'id_siagprf', label: 'Atender'},
        {key: 'horario', label: 'Horário'},
        {key: 'paciente.no_usuario', label: 'Paciente'},
        {key: 'paciente.idade', label: 'Idade'},
        {key: 'sit_ident', label: 'Situação'},
        {key: 'paciente.ddd', label: 'DDD'},
        {key: 'paciente.fone_celular', label: 'Celular'},
      ],
      api: null,
      volume: 'volume_up',
      camera: 'videocam',
      texto: '',
      profissionais: [],
      profissionalSelecionado: '',
      pacienteSelecionado: '',
      usuarioSelecionado: '',
      formaSelecionada: {},        //Usado para manter a forma selecionada pelo usuario
      criando: true,
      nomeSala: null,
      timer: null,
      channel: null,
      realizandoPesquisa: false,
      sala: null,
      identificador: null,
      eventos: []
    }
  },
  mounted() {

    if ("Notification" in window) {
      //alert(Notification.permission);
      if (Notification.permission === "granted") {
        this.permiteNotificacoes = true;
        this.$store.commit('setPermiteNotificacoes', true);
        //alert(Notification.permission);
        // If it's okay let's create a notification
        //var notification = new Notification("Hi there!");
      } else if (Notification.permission !== 'denied') {
        //alert(Notification.permission);
        Notification.requestPermission()
            .then(perm => {
              if (perm === "granted") {
                this.permiteNotificacoes = true;
                this.$store.commit('setPermiteNotificacoes', true);
              } else {
                //console.log(perm);
                this.permiteNotificacoes = false;
                this.$store.commit('setPermiteNotificacoes', false);
              }

            })
            .catch()
      }
    }

    //let param = this.$store.getters.getParametros
    //this.tipoIntegracao = param.tipo_integracao;
    //console.log('Parametros:');
    //console.log(param);


    let user = this.$store.getters.currentUser;
    if (user.nome == '' && this.params.ibge != '') {
      this.isLoadingForma = true;
      this.$store.dispatch(VERIFY_AUTH)
          .then(resp => {
            this.isLoadingForma = false;
            let user = this.$store.getters.currentUser;
            let param = this.$store.getters.getParametros
            if (user && user.profissional) {
              this.channel = this.$pusher.subscribe(`${this.params.ibge}-profissional-${user.profissional.codigo}`);
              this.channel.bind('sala-criada', this.trataEventoSalaCriada);
              this.channel.bind('convidado-logou', () => {
                //console.log('Convidado Logou');
              });
            }
          });
    } else {
      if (this.usuario) {
        this.channel = this.$pusher.subscribe(`${this.params.ibge}-profissional-${user.profissional.codigo}`);
        this.channel.bind('sala-criada', this.trataEventoSalaCriada);
        this.channel.bind('convidado-logou', () => {
          //console.log('Convidado Logou');
        });
      }
    }

    ApiService.setHeader();
    //this.$store.dispatch(SET_BREADCRUMB, [{title: "Dashboard"}]);

    /*let formas = this.$store.getters.getFormas;
    if (!formas || formas.length == 0) {
      this.$store
        .dispatch(BUSCA_FORMAS);
    }*/

    this.sala = this.$store.getters.getSala;
    //console.log(this.sala);
    if (this.sala && this.sala.sala) {
      if (confirm('Você possui uma sala em andamento, deseja retornar a ela?')) {
        this.nomeSala = this.sala.sala;
        this.criaSalaStreamer();
        this.$store.dispatch(VERIFY_AUTH);
        //console.log('vai pra sala');
      } else {
        this.$store.dispatch(FECHA_SALA, {});
      }
    }

  },
  computed:  {
    formas() {
      return this.$store.getters.getFormas;
    },
    usuario() {
      return this.$store.getters.currentUser;
    },
    carregandoFormas() {
      let user = this.$store.getters.currentUser;
      return user.nome == '';
    },
    agendamentos() {
      return this.$store.getters.getAgendamentos;
    },
    telefonesGrupoValidos() {
      let retorno = true;
      //console.log(this.selected);
      this.selected.forEach(item => {
        //console.log(item.telefone);
        if (!item.telefone || item.telefone.trim() == '') retorno = false;
      });
      return retorno;
    },
    params(){
      return this.$store.getters.getParametros;
    }
  },
  methods: {
    copia(link){
      //alert(el);
      var dummy = document.createElement('input'),
          text = link;
      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
      this.$bvToast.toast('O Link foi copiado para a área de tansferência.', {
        title: 'Link Copiado',
        variant: 'success',
        toaster: 'b-toaster-top-right',
        solid: true
      });

    },
    apiWhats() {
      //alert(window.encodeURIComponent(texto));
      //`${item.paciente.ddd}${item.paciente.fone_celular}`,`${link}/#/login?p=${ite
      //console.log('222222222222222');
      var url = 'https://api.whatsapp.com/send?phone=+55'+
          this.sala.participantes[0].paciente.ddd +
          this.sala.participantes[0].paciente.fone_celular +
          '&text=';
      var texto = '';
      if (this.sala.participantes[0].paciente) {
        texto = `${LINK}/#/login?p=${this.sala.participantes[0].id}`
      }
      var encodeLink = window.encodeURIComponent(texto);
      var destino = url + encodeLink;
      //console.log(destino);
      window.open(destino, '_blank');
    },
    selectAllRows() {
      //console.log('linhas selecionadas');
      //console.log(this.$refs.tabelaAgendamentoGrupo);
      this.$refs.tabelaAgendamentoGrupo.selectAllRows();

    },
    clearSelected() {
      this.$refs.tabelaAgendamentoGrupo.clearSelected()
    },
    nomeDaSala(value) {
      let aux = new Date();
      if (this.tipoEvento == 'Grupo') {
        return `${this.usuario.id}-${this.grupoSelecionado.id_iaggru}-${aux.getFullYear()}${('00' + aux.getMonth()).slice(-2)}${('00' + aux.getDay()).slice(-2)}`;
      } else if (this.tipoEvento == 'Usuario') {
        return `${this.usuario.id}-${this.usuarioSelecionado.id}-${aux.getFullYear()}${('00' + aux.getMonth()).slice(-2)}${('00' + aux.getDay()).slice(-2)}`;
      } else if (this.tipoEvento == 'Paciente') {
        return `${this.usuario.id}-${value}-${aux.getTime()}`;
      } else if (this.tipoEvento == 'Profissional') {
        return `${this.usuario.id}-${this.profissionalSelecionado.codigo}-${aux.getTime()}`;
      }
      return '';
    },
    criaSalaStreamer() {
      const options = this.getOptions();
      this.api = new JitsiMeetExternalAPI(DOMAIN, options);
    },
    /*Metodo usando na Tabela de agendamentos em grupo para seleção dos participantes da video*/
    handleUpload() {
      //console.log(this.arquivoSelecionado);
      this.enviandoArquivo = true;
      let formData = new FormData();
      formData.append('arquivo', this.arquivoSelecionado[0]);
      formData.append('sala', this.sala.id);
      ApiService.setHeader();
      ApiService.setHeaderFileSend();
      ApiService.post('files', formData)
          .then(ret => {
            //console.log(ret);
            this.$store.dispatch(ADD_FILE, ret.data.image);
            this.enviandoArquivo = false;
          })
          .catch(e => {
            //console.log(e);
          })
          .finally(() => console.log('finalizou'));
    },

    /*Metodos Usados Para Profisionais*/
    criaSalaProfissional() {
      this.tipoEvento = 'Profissional';

      let button = this.$refs.bt_cria_sala_profissional;

      this.nomeSala = this.nomeDaSala();
      let options = {
        sala: this.nomeSala,
        profissional: this.profissionalSelecionado.codigo
      }
      this.criando = true;
      this.criarSala(options, button);
    },
    criaSalaPaciente(agendamentoId) {
      this.tipoEvento = 'Paciente';
      this.nomeSala = this.nomeDaSala(agendamentoId);
      let options = {
        sala: this.nomeSala,
        agendamento: agendamentoId
      };
      this.criando = true;
      this.criarSala(options);
    },
    buscaProfissionais() {
      this.profissionalSelecionado = '';
      this.isLoadingProfissional = true;
      ApiService
          .get(`profissionais/${this.formaSelecionada.sforma}`)
          .then(res => {
            if (res.data.status) {
              this.profissionais = res.data.profissionais;
            } else alert('Erro......');
            this.isLoadingProfissional = false;
          })
          .catch(e => {
            //console.log(e);
            alert('Erro.....' + e.getMessage());
          });
    },

    /*Metodos usados para Grupos*/
    onRowSelected(items) {
      this.selected = [];
      items.forEach(e => {
        var telefone = '';
        this.selected.push(
            {
              pacienteId: e.id_usuario_gms,
              dataNascimento: e.paciente.dt_nascimento,
              telefone: e.paciente.fone_celular,
              ddd: e.paciente.ddd,
              nomePaciente: e.paciente.no_usuario
            });
      });
    },
    buscaParticipantes(e) {
      //console.log(this.grupoSelecionado);
      //console.log('33333333333333333333333333');
      this.tabelaGrupoBusy = true;
      ApiService.get('participantes', this.grupoSelecionado.id_iaggru)
          .then(ret => {
            this.agendamentosGrupo = ret.data.participantes;
          })
          .catch(error => {
            console.log(error);
            this.tabelaGrupoBusy = false;
          })
          .finally(() => {
            this.tabelaGrupoBusy = false;
            for (let i = 0; i < this.$refs.tabelaAgendamentoGrupo.localItems.length; i++) {
              //console.log(this.$refs.tabelaAgendamentoGrupo.localItems[i]);
              if (this.$refs.tabelaAgendamentoGrupo.localItems[i].paciente.fone_celular != null &&
                  this.$refs.tabelaAgendamentoGrupo.localItems[i].paciente.fone_celular != '') {
                this.$refs.tabelaAgendamentoGrupo.selectRow(i);
              }
            }
            //this.$refs.tabelaAgendamentoGrupo.selectAllRows();
          })
    },

    criaSalaGrupo() {
      this.tipoEvento = 'Grupo';
      this.nomeSala = this.nomeDaSala();
      const btn = this.$refs.bt_cria_sala_grupo;

      btn.classList.add("spinner", "spinner-light", "spinner-right");

      //const domain = 'meet.eplansoft.com.br';
      //const options = this.getOptions();
      //this.api = new JitsiMeetExternalAPI(DOMAIN, options);
      this.criaSalaStreamer();

      let novaSala = {
        sala: this.nomeSala,
        profissional: this.profissionalSelecionado.codigo,
        pacientes: this.selected
      }

      ApiService.post('salas', novaSala).then(resp => {

        this.sala = resp.data.sala;
        this.criando = false;
        this.$store.dispatch(SET_SALA, resp.data.sala);
        this.toast('Sala Criada!',
            'A sala foi criada e registrada.',
            'success',
            'b-toaster-top-right');


        this.nomeSala = null;
        btn.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
        );
      })
          .catch(e => console.log(e));
    },

    /*Metodos usados para usuários do sistema*/
    criaSalaPacienteAvulso() {
      //alert('clicou');
      this.tipoEvento = 'Paciente';
      this.nomeSala = this.nomeDaSala(this.pacienteSelecionado.id_usuario_gms);
      let btn = this.$refs.bt_cria_sala_paciente_avulso;
      let options = {
        sala: this.nomeSala,
        paciente: this.pacienteSelecionado.id_usuario_gms
      };
      this.criando = true;
      //alert('lskdlksl');
      //console.error('çlaskdlçaskdçaskdlçaskdlçkasdlçkaslçd');
      this.criarSala(options, btn);
    },
    pacienteFoiSelecionado(paciente) {
      this.pacienteSelecionado = paciente;
    },

    /*Metodos de Manipulação da Sala de Video*/
    fullScreen() {
      var videoElement = document.getElementById("meet");

      if (!document.mozFullScreen && !document.webkitFullScreen) {
        if (videoElement.mozRequestFullScreen) {
          videoElement.mozRequestFullScreen();

        } else {
          videoElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        }

      } else {

        if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else {
          document.webkitCancelFullScreen();
        }
      }

    },
    criarSala(opcoesPost, button) {
      //console.log('CRIANDO SALA............................')
      /*const domain = 'meet.eplansoft.com.br';
      const options = this.getOptions();*/

      /*
              const submitButton = this.$refs["bt_cria_sala"];
              submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      */
      if (button) {
        button.classList.add("spinner", "spinner-light", "spinner-right");
      }

      /*navigator.getUserMedia(
          // constraints
          {
              video: true,
              audio: true
          },

          // successCallback
          function (localMediaStream) {
              //var video = document.querySelector('video');
              //video.src = window.URL.createObjectURL(localMediaStream);
              //video.onloadedmetadata = function (e) {
                  // Do something with the video here.
              //};
          },

          // errorCallback
          function (err) {
              if (err === PERMISSION_DENIED) {
                  // Explain why you need permission and how to update the permission setting
                  console.log(err);
              }
          }
      );*/

      /*this.api = new JitsiMeetExternalAPI(domain, options);*/

      this.volume = 'volume_up';
      this.camera = 'videocam';

      //this.criaSalaStreamer();
      const options = this.getOptions();
      //console.log('5555555555555555555');
      this.api = new JitsiMeetExternalAPI(DOMAIN, options);
      //console.log('4444444444444444');

      if (this.criando) {
        //console.log('Sala: ' + this.nomeSala);
        //console.log(opcoesPost);
        //alert('vai mandar');
        ApiService.post('salas', opcoesPost).then(resp => {
          this.sala = resp.data.sala;
          this.criando = false;
          this.$store.dispatch(SET_SALA, resp.data.sala);
          this.toast('Sala Criada!',
              'A sala foi criada e registrada.',
              'success',
              'b-toaster-top-right');
          this.nomeSala = null;
          if (button) {
            button.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
            );
          }
        })
            .catch(e => console.log(e));
      } else {
        this.nomeSala = null;
      }

      setTimeout(() => {
        this.api.on('tileViewChanged ', (ret) => {
          //console('11111111111111111111111111111111111111111')
          //console.log(ret);
        });
        this.api.addEventListener('endpointTextMessageReceived', this.teste);
        this.api.addEventListener('videoConferenceJoined', (response) => {
        });
        this.api.addEventListener('participantJoined ', (response) => {
          //console.log('Mais um participante');
          //console.log(response);
        });
        this.api.addEventListener('incomingMessage', this.teste);
        this.api.addEventListener('readyToClose', this.readyToClose);
        this.api.addEventListener('participantRoleChanged', e => {
          //console.log('Mudou o Role');
          //console.log(e);
          //this.api.executeCommand('password', '1234');
        });
        this.api.isAudioMuted().then(muted => {
          if (muted) this.volume = 'volume_off';
          else this.volume = 'volume_up';
        });
        this.api.isVideoMuted().then(muted => {
          if (muted) this.camera = 'videocam_off';
          else this.camera = 'videocam';
          //console.log('Video Muted');
        });
        //custJitsiAPI.on('readyToClose', () => {console.log('call hung up fron ON event');});


      }, 6000);

    },
    encerrarSala() {
      this.api.dispose();
      this.api = null;
      this.$store.dispatch(RESET);
      ApiService
          .get('salas/encerrar', this.sala.id)
          .catch(e => {
            alert('Erro encerrando a Sala');
            console.log(e);
          })
          .finally(() => {
            this.toast('Sala Encerrada', 'A sala foi encerrada com sucesso', 'success');
            this.$store.dispatch(FECHA_SALA, {});
          })
    },
    mudaSubject() {
      this.api.executeCommand('subject', this.texto);
    },
    toggleAudio() {
      this.volume = this.volume == 'volume_off' ? 'volume_up' : 'volume_off';
      this.api.executeCommand('toggleAudio');
    },
    toggleVideo() {
      this.camera = this.camera == 'videocam' ? 'videocam_off' : 'videocam';
      this.api.executeCommand('toggleVideo');
    },
    toggleFilmStrip() {
      this.api.executeCommand('toggleFilmStrip');
    },
    toggleChat() {
      this.api.executeCommand('toggleChat');
    },
    hangup() {
      this.api.executeCommand('hangup');
    },
    mudaEmail() {
      this.api.executeCommand('email', 'example@example.com');
    },
    sendTexto() {
      this.api.executeCommand('sendEndpointTextMessage', 'receiverParticipantId', 'text');
    },
    setVideoQuality() {
      this.api.executeCommand('setVideoQuality', 720);
    },
    muteEveryone() {
      this.api.executeCommand('muteEveryone');
    },
    poeSenha() {
      //this.api.executeCommand('password', '1234');
      //console.log('Senha!!!!');
    },
    readyToClose() {
      //console.log('hanghup acionado!');
      this.api.dispose();
      this.api = null;
    },
    sendSMS() {
      /*Busca identificador do SMS*/
      let telefone = '';
      this.sala.participantes.forEach(el => {
        ApiService.get('sms')
            .then(ret => {
              /*Envia o SMS com identificador correto*/
              this.identificador = ret.data.identificador;
              //console.log(el);
              if (el.profissional_id != null)
                telefone = el.profissional.ddd + ' ' + el.profissional.fone_celular;
              else if (el.paciente_id != null)
                telefone = el.paciente.ddd + ' ' + el.paciente.fone_celular;
              this.dispatchSMS(telefone, this.identificador, el.id);
            })
            .catch();
      });

    },
    dispatchSMS(telefone, identificador, chave) {
      const botaoEnvia = this.$refs["bt_envia_sms"];
      botaoEnvia.classList.add("spinner", "spinner-light", "spinner-right");
      this.$http
          .post("https://api-rest.zenvia.com/services/send-sms",
              {
                "sendSmsRequest": {
                  "from": "SMS Brusque",
                  "to": "55" + telefone,
                  "msg": `Link de acesso: ${LINK}/#/login?p=${chave}`,
                  "callbackOption": "NONE",
                  "id": identificador,
                  "aggregateId": "1112",
                  //"flashSms": false
                }
              },
              {
                "headers": {
                  "Authorization": "Basic " + "aW5vdmFkb3JhLmd3OnQ3S3pZRThkMnY=",
                  "Content-Type": "application/json",
                  "Accept": "application/json"
                },
                crossDomain: true
              }
          )
          .then(response => {
            if (response.data.sendSmsResponse.statusCode == '00' &&
                response.data.sendSmsResponse.detailCode == '000') {
              this.eventos.push({
                'evento': 'Senha',
                'message': `SMS Enviado para o nr. ${telefone}`
              });
              botaoEnvia.classList.remove(
                  "spinner",
                  "spinner-light",
                  "spinner-right"
              );
            }
          })
          .then(() => {
            var self = this;
            setTimeout(() => {
              self.getStatusSMS(identificador)
            }, 60000)
          })
          .catch(e => {
            console.log(e);
            alert('Erro ao enviar o SMS')
          });
    },
    getStatusSMS(identificador) {
      let botaoEnvia = this.$refs["bt_envia_sms"];
      this.$http
          .get("https://api-rest.zenvia.com/services/get-sms-status/" + identificador,
              {
                "headers": {
                  "Authorization": "Basic " + "aW5vdmFkb3JhLmd3OnQ3S3pZRThkMnY=",
                  "Content-Type": "application/json",
                  "Accept": "application/json"
                },
                crossDomain: true
              }
          )
          .then(response => {
            if (response.data.getSmsStatusResp.detailCode == "120" &&
                response.data.getSmsStatusResp.statusCode == "03") {
              this.eventos.push({'evento': 'Senha', 'message': 'SMS entregue para o Destinatário.'});
              this.toast('SMS Enviado com Sucesso', 'O SMS foi entregue com sucesso ao destinatário!');
            } else if (response.data.getSmsStatusResp.detailCode == "110" &&
                response.data.getSmsStatusResp.statusCode == "02") {
              this.eventos.push({'evento': 'Senha', 'message': 'SMS entregue para a Operadora.'});
            }
          })
          .catch(e => {
            console.log(e);
            alert('Fora do ar....')
          });
    },
    getOptions() {
      let nome = '';
      let aux = new Date();
      if (!this.nomeSala) {
        //console.log(this.profissionalSelecionado);
        nome = this.nomeSala = this.usuario.id + '-' + this.profissionalSelecionado.usuario.id + '-' + aux.getTime();
        //console.log('Era Null -> ' + this.nomeSala);
      } else {
        nome = this.nomeSala;
        //console.log('Não era Null -> ' + this.nomeSala);
      }
      var info = {};
      //console.log(this.usuario);
      if (this.usuario.profissional) info.displayName = this.usuario.profissional.descricao;
      else info.displayName = this.usuario.nome;
      return {
        roomName: nome,
        userInfo: info,
        //width: 400,
        //height: 600,
        noSsl: false,
        parentNode: document.querySelector('#meet'),
        interfaceConfigOverwrite: {
          SHOW_JITSI_WATERMARK: true,
          JITSI_WATERMARK_LINK: 'https://inovadora.com.br',
          SHOW_WATERMARK_FOR_GUESTS: false,
          SHOW_BRAND_WATERMARK: false,
          BRAND_WATERMARK_LINK: '',

          DISPLAY_WELCOME_PAGE_CONTENT: false,
          APP_NAME: 'Consulta Online',

          TOOLBAR_BUTTONS: [
            //'microphone',
            //'camera',
            //'closedcaptions',
            'desktop',
            //'fullscreen',
            'fodeviceselection',
            //'hangup',
            //'profile',
            //'chat',
            //'recording',
            //'livestreaming',
            //'etherpad',
            'sharedvideo',
            //'settings',
            //'raisehand',
            //'videoquality',
            //'filmstrip',
            //'invite',
            //'feedback',
            //'stats',
            //'shortcuts',
            //'tileview',
            //'videobackgroundblur',
            //'download',
            //'help',
            'mute-everyone',
            //'e2ee',
            //'security'
          ],
        },
        configOverwrite: {defaultLanguage: 'ptBR', disableDeepLinking: true},
      };
    },

    entraConferencia(item) {
      if (item.finalizada) return false;
      this.nomeSala = item.sala;
      this.criando = false;
      this.criarSala();
      this.$http
          .post(`${this.$urlAPI}salas/acessou/${item.id}`, {},
              {
                "headers": {"authorization": "Bearer " + this.$store.getters.getToken}
              })
          .then(response => {
            if (response.data.status) {

            } else if (response.data.status == false && response.data.validacao == true) {
              alert('Erro finalizando Sala!');
            }
          })
          .catch(e => {
            //console.log(e);
            alert('Erro ao finalizar a sala')
          });
    },

    /*Gerenciamento de Eventos*/
    trataEventoSalaCriada(data) {
      //console.log('passando evento xxxx');
      //console.log(data);
      //this.$store.commit('addSala', data.sala);
      this.notificacaoSalaCriada(data.sala);
      //console.log(data);
    },

    testes(ret) {
      console.log(ret);
    },
    teste() {
      this.toast('teste', 'sdfsdfsdfs', 'success', 'b-toaster-bottom-center')
    },
    toast(titulo, mensagem, variant, toaster) {
      if (toaster == null) toaster = 'b-toaster-top-right';
      this.$bvToast.toast(mensagem, {
        title: titulo,
        variant: variant,
        toaster: toaster,
        solid: true
      });

    },
    notifyme(message) {
      if (this.permiteNotificacoes) {
        var notification = new Notification(message);
      }
    },
    notificacaoSalaCriada(sala) {
      //console.log('1111111111111111111');
      let titulo = 'Sala Criada!';
      let mensagem = `Conferência solicitada por: ${sala.nome_usuario_origem}\nAcesse a Sala usando a chave: ${sala.participantes[0].chave_sala}!`;
      this.notifyme(mensagem);
      this.toast(titulo, mensagem, 'info');
    },
    async pip() {
      chrome.tabs.executeScript({
        code: `
          const videos = Array.from(document.querySelectorAll('video'))
          .filter(video => video.readyState != 0)
          .filter(video => video.disablePictureInPicture == false)
          .sort((v1, v2) => {
          const v1Rect = v1.getClientRects()[0];
          const v2Rect = v2.getClientRects()[0];
           return ((v2Rect.width * v2Rect.height) - (v1Rect.width * v1Rect.height));
           });

         if (videos.length === 0)
           return;

         const video = videos[0];

         if (video.hasAttribute('__pip__')) {
           await document.exitPictureInPicture();
         } else {
           await video.requestPictureInPicture();
           video.setAttribute('__pip__', true);
           video.addEventListener('leavepictureinpicture', event => {
           video.removeAttribute('__pip__');
          }, { once: true });
         chrome.runtime.sendMessage({ message: 'enter' });
         }
`
      });

    }
  },
  watch: {
    searchGrupos(e) {
      //console.log(e)
      if (e && !this.carregandoGrupos && e.length > 5 && !this.tabelaGrupoBusy) {
        this.carregandoGrupos = true;
        ApiService.post('agendamentogrupo', {q: e})
            .then(ret => {
              //console.log(ret);
              this.grupos = ret.data.grupos;
            })
            .finally(() => this.carregandoGrupos = false);
      }
    },
    selected() {
      //console.log('watched');
      //console.log(this.telefonesGrupoValidos)
    }
  }
};
</script>
